@font-face {
  font-family: "New Transport AA";
  src: url("./fonts/NewTransportAA-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/NewTransportAA-Light.woff") format("woff"),
    url("./fonts/NewTransportAA-Light.svg#NewTransportAA-Light") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "New Transport AA";
  src: url("./fonts/NewTransportAA-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/NewTransportAA-Regular.woff") format("woff"),
    url("./fonts/NewTransportAA-Regular.svg#NewTransportAA-Regular")
      format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "New Transport AA";
  src: url("./fonts/NewTransportAA-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/NewTransportAA-Italic.woff") format("woff"),
    url("./fonts/NewTransportAA-Italic.svg#NewTransportAA-Italic") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "New Transport AA";
  src: url("./fonts/NewTransportAA-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/NewTransportAA-Medium.woff") format("woff"),
    url("./fonts/NewTransportAA-Medium.svg#NewTransportAA-Medium") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "New Transport AA";
  src: url("./fonts/NewTransportAA-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/NewTransportAA-Bold.woff") format("woff"),
    url("./fonts/NewTransportAA-Bold.svg#NewTransportAA-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

ul {
  list-style: outside;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

.disable-css-transitions {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
