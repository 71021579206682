#surprise {
  flex: 1;
}
#surprise * {
  transition: all 0.3s cubic-bezier(0.6, -0.5, 0.6, 1.25);
}
#surprise.close--lid #box-main {
  transform: rotate(8deg) translateX(20px) translateY(0px);
}
#surprise.close--lid #box-lid {
  transform: rotate(23deg) translateX(45px) translateY(2px) scale(1.05);
}
#surprise.close--lid #shadow-1 {
  opacity: 0.05;
}
#surprise.close--lid #shadow-2 {
  opacity: 0.3;
}
#surprise.close--lid #pound-note {
  transform: rotate(-40deg) translateX(-130px) translateY(140px) scale(0.9);
}
#surprise.close--lid #steering-wheel {
  transform: rotate(30deg) translateX(100px) translateY(-30px);
}
#surprise.close--lid #surprise-text {
  opacity: 1;
  transform: translateY(-60px);
}
#surprise.close--lid #star-1 {
  transform: rotate(30deg) translateX(120px) translateY(-20px);
}
#surprise.close--lid #star-2 {
  transform: translateX(-100px) translateY(100px);
}
#surprise.close--lid #circle-1 {
  transform: translateX(70px) translateY(10px);
}
#surprise.close--lid #circle-2 {
  transform: scale(2) translateX(-130px) translateY(60px);
}
#surprise.close--lid #circle-3 {
  transform: scale(2) translateX(-160px) translateY(10px);
}
